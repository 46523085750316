<template>
  <div class="contact-container">
    <div class="contact-box row m-0">
      <div class="col-12 col-lg-4 detail">
        <div>
          <div class="box">
            <div class="item">
              <div class="icon">
                <i class="fas fa-map-marker-alt"></i>
              </div>
              <div class="content">
                <div class="">ADDRESS</div>
                <div>
                  1000/7 โครงการเดอะมาสเตอร์ รีเฟลคซ์ชั่น @ บีทีเอสอ่อนนุช ถนน
                  อ่อนนุช กทม
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="item">
              <div class="icon">
                <i class="fas fa-phone-alt"></i>
              </div>
              <div class="content">
                <div class="">PHONE</div>
                <div><a href="tel:0629366228">062-936-6228</a></div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="item">
              <div class="icon">
                <i class="fas fa-envelope"></i>
              </div>
              <div class="content">
                <div class="">Facebook messenger</div>
                <div>
                  <a href="https://m.me/revealingclinic" target="_blank"
                    >m.me/revealingclinic</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="item">
              <div class="icon">
                <i class="fas fa-envelope"></i>
              </div>
              <div class="content">
                <div class="">Line @</div>
                <div>
                  <a
                    href="http://line.me/ti/p/~@revealingclinic"
                    target="_blank"
                  >
                    @revealingclinic
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg map">
        <iframe
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=revealing%20clinic&t=&z=17&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  metaInfo: {
    title: "ติดต่อเรา",
    meta: [
      {
        name: "og:title",
        content: "ติดต่อเรา | Revealing Clinic"
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/color";
@import "@scss/media";

a {
  color: $dark1;
  text-decoration: underline;
}
.contact-container {
  .detail {
    background: $white2;
    display: flex;
    align-items: center;
    padding: 30px;
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5vh;

      .icon {
        text-align: left;
        padding: 15px;
        padding-right: 30px;
        i {
          font-size: 3rem;
          width: 50px;
          color: $dark1;
        }
      }

      .content {
        // title
        div:first-child {
          font-size: 23px;
          color: $pink1;
        }
        // text
        div:last-child {
          font-size: 15px;
        }
      }
    }
  }
  .map {
    padding-left: 50px;

    @include small {
      padding: 0;
    }

    @include medium {
      padding: 0;
    }

    @include desktop {
      padding: 0;
    }

    iframe {
      width: 100%;
      height: 100%;
      min-height: 60vh;
    }
  }
}
</style>
